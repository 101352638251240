import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
// services
import { setCookie } from '@services/cookies';
import { isUsingGBPCurrency } from '@services/global';
import { trackProductViewed } from '@services/tracking/tracking';
//constants
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';
import { OFFER_CODES } from '@src/constants';
import { ERROR_MESSAGES } from '@src/constants';
// components
import Layout from '@components/layout';
import SEO from '@components/seo';
import AddToBasket from '@components/add-to-basket';
import Loader from '@components/loader';
import PersonalizedPageContent from '@components/personalized-page-content/personalized-page-content';
import Image from '@components/image/image';
// hooks
import { useProductPersonalisedText } from '@hooks/directusHooks/useProductPersonalisedText';
import usePersonalizePlan from '@hooks/usePersonalizePlan';
import useGlobalError from '@hooks/useGlobalError';
// styles
import styles from './personalised.module.scss';
import { GlobalContext } from '@src/store/global-state';
import couponCodeService from '@src/utils/couponCodeService';
import { essentialPageVisitSetCookie, fourPPPageVisitSetCookie } from '@src/utils/coupons';

interface IProps {
  isLandingPage: boolean;
  isFreeTrial: boolean;
  noindex?: boolean;
}

const PersonalisedPage: React.FC<IProps> = ({ isLandingPage, isFreeTrial, noindex = false }) => {
  const location = useLocation();
  const { isLoggedIn } = useContext(GlobalContext);
  const { setOffer } = couponCodeService();

  const { directusProductPersonalised } = useProductPersonalisedText();

  const {
    getRecommendedProducts,
    loadingProducts,
    loadingPlans,
    errorProducts,
    errorPlans,
    plans,
    personalisedProductPrice,
    personalisedProductRecommendation,
  } = usePersonalizePlan();

  useEffect(() => {
    if (isFreeTrial) {
      setOffer('4pp', 'freeTrial');
    } else {
      fourPPPageVisitSetCookie();
    }

    trackProductViewed({
      name: 'Personalised Pack',
      url: location.href,
      product_id: 'personalised',
      category: 'personalised',
      variant: personalisedProductRecommendation?.id,
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) getRecommendedProducts();
  }, [isLoggedIn]);

  const error = errorProducts || errorPlans;
  useGlobalError(!!error, ERROR_MESSAGES.generic);

  if (loadingPlans || loadingProducts) return (
    <>
      <SEO
        noindex={noindex}
        title={
          isFreeTrial ? directusProductPersonalised.page_title_trial : directusProductPersonalised.page_title
        }
        description={
          isFreeTrial
            ? directusProductPersonalised.page_description_trial
            : directusProductPersonalised.page_description
        }
      />
      <Loader />
    </>
  );

  return (
    <Layout showFooter={!isLandingPage}>
      <SEO
        noindex={noindex}
        title={
          isFreeTrial
            ? directusProductPersonalised.page_title_trial
            : directusProductPersonalised.page_title
        }
        description={
          isFreeTrial
            ? directusProductPersonalised.page_description_trial
            : directusProductPersonalised.page_description
        }
      />

      <section className={styles.sectionHero}>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.imageLeftLarge}>
              <Image
                filename={directusProductPersonalised.hero_image.filename_disk}
                alt={directusProductPersonalised.hero_image.title}
                width={700}
              />
            </div>
            <div className={styles.contentWrapperLeft}>
              <div className={styles.heroContent}>
                <h1>
                  {isFreeTrial && isUsingGBPCurrency()
                    ? directusProductPersonalised.hero_title_trial
                    : directusProductPersonalised.hero_title}
                </h1>
                <hr className={styles.hrCentered} />
                <div
                  className={styles.heroDescription}
                  dangerouslySetInnerHTML={{
                    __html:
                      isFreeTrial && isUsingGBPCurrency()
                        ? directusProductPersonalised.hero_description_trial
                        : !personalisedProductPrice
                        ? directusProductPersonalised.hero_description_no_price
                        : directusProductPersonalised.hero_description.replace(
                            '{price}',
                            personalisedProductPrice
                          ),
                  }}
                />
                {plans ? (
                  <div>
                    <AddToBasket
                      plans={plans}
                      isFreeTrial={isFreeTrial}
                      productType={OFFER_CODES.PersonalisedPlus}
                    />
                    {isUsingGBPCurrency() && (
                      <div
                        className={styles.footnoteNoMargin}
                        dangerouslySetInnerHTML={{
                          __html: directusProductPersonalised.hero_footnote,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <div>
                    <Link className={styles.button} to={'/consultation'}>
                      {directusProductPersonalised.feel_best_button_label}
                    </Link>
                    <p className={styles.footnote}>
                      {isUsingGBPCurrency() && (
                        <span>
                          Free UK delivery
                          <br />
                        </span>
                      )}
                      Pause or cancel subscription any time
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <PersonalizedPageContent />

      <section className={styles.sectionLast}>
        <div className={styles.rowLast}>
          <div className={styles.image}>
            <Image
              filename={directusProductPersonalised.feel_best_image.filename_disk}
              width={544}
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <h2>
                {isFreeTrial && isUsingGBPCurrency()
                  ? directusProductPersonalised.feel_best_title_trial
                  : directusProductPersonalised.feel_best_title}
              </h2>
              <hr />
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      isFreeTrial && isUsingGBPCurrency()
                        ? directusProductPersonalised.feel_best_description_trial
                        : !personalisedProductPrice
                        ? directusProductPersonalised.feel_best_description_no_price
                        : directusProductPersonalised.feel_best_description.replace(
                            '{price}',
                            personalisedProductPrice
                          ),
                  }}
                />
                {plans ? (
                  <AddToBasket
                    plans={plans}
                    isFreeTrial={isFreeTrial}
                    productType={OFFER_CODES.PersonalisedPlus}
                  />
                ) : (
                  <Link className={styles.button} to={'/consultation'}>
                    {directusProductPersonalised.feel_best_button_label}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PersonalisedPage;
